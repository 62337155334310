import { Component } from '@angular/core';
import { ApiService } from '../services/api.service';
import { User } from '../interfaces/user';
import { Order } from '../interfaces/order';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { NumberPipe } from '../pipes/number.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';

interface UserRFM {
  name: string;
  phone: string;
  bitrixID: string;
  amountMonth: number;
  amountQuarter: number;
  vikupMonth: number;
  vikupQuarter: number;
  amountTotal: number,
  amountLast51: number,
  amountLast112: number,
  isRetail: boolean,
  status: string,
  statusRu: string,
}

@Component({
  selector: 'app-rfm',
  standalone: true,
  imports: [NgClass, MatTooltipModule, FormsModule, NumberPipe],
  templateUrl: './rfm.component.html',
  styleUrl: './rfm.component.scss'
})

export class RfmComponent {
  constructor(private apiService: ApiService){
    apiService.getUserList().subscribe((usersList)=>{
      if(usersList){
        this.users = usersList.map((user) => ({
          name: user.name || '',
          phone: user.phone || '',
          bitrixID: user.bitrixID || '',
          amountMonth: 0,
          amountQuarter: 0,
          vikupMonth: 0,
          vikupQuarter: 0,
          amountTotal: 0,
          amountLast51: 0,
          amountLast112: 0,
          isRetail: user.person === 'Розничный покупатель',
          status: '',
          statusRu: '',
        }));
        this.usersSorted = [...this.users];
      }else{
        console.error("Пользователи не вытянулись");
        this.errorMessage = 'Пользователи не вытянулись';
      }
    });
    apiService.getOrderList('').subscribe((ordersList)=>{
      if(ordersList){
        this.orders = ordersList;
        const currentDate = new Date();
        const oneMonthAgo = new Date();
        const currentYear = currentDate.getFullYear();
        let orderDate51 = new Date(); 
        orderDate51.setDate(currentDate.getDate()-51);
        let orderDate112 = new Date(); 
        orderDate112.setDate(currentDate.getDate()-112);
        
        let quarterStart: Date, quarterEnd: Date;

        if (currentDate.getMonth() < 3) { // Q1: Jan - Mar
          quarterStart = new Date(currentYear, 0, 1); // Jan 1
          quarterEnd = new Date(currentYear, 2, 31); // Mar 31
        } else if (currentDate.getMonth() < 6) { // Q2: Apr - Jun
          quarterStart = new Date(currentYear, 3, 1); // Apr 1
          quarterEnd = new Date(currentYear, 5, 30); // Jun 30
        } else if (currentDate.getMonth() < 9) { // Q3: Jul - Sep
          quarterStart = new Date(currentYear, 6, 1); // Jul 1
          quarterEnd = new Date(currentYear, 8, 30); // Sep 30
        } else { // Q4: Oct - Dec
          quarterStart = new Date(currentYear, 9, 1); // Oct 1
          quarterEnd = new Date(currentYear, 11, 31); // Dec 31
        }

        oneMonthAgo.setMonth(currentDate.getMonth() - 1);
        const orderCountMonthByPhone: { [phone: string]: number } = {};
        const orderMonthByPhone: { [phone: string]: number } = {};
        const orderCountByPhoneQuarter: { [phone: string]: number } = {};
        const orderQuarterByPhone: { [phone: string]: number } = {};

        const orderCountTotal: { [phone: string]: number } = {};
        const orderCountTotal51: { [phone: string]: number } = {};
        const orderCountTotal112: { [phone: string]: number } = {};

        this.orders.forEach((order) => {
          if (order.phone && order.created) {
            // Parse the order.created date (expected format: DD/MM/YY)
            const parts = order.created.split('/');
            if (parts.length === 3) {
              const day = parseInt(parts[0], 10);
              const month = parseInt(parts[1], 10) - 1; // Months are 0-based
              const year = parseInt(parts[2], 10) + 2000; // Convert YY to YYYY
              const orderDate = new Date(year, month, day);
              
              // Check if the order date is within the past month
              if (orderDate >= oneMonthAgo && orderDate <= currentDate) {
                orderCountMonthByPhone[order.phone] = (orderCountMonthByPhone[order.phone] || 0) + 1;
                orderMonthByPhone[order.phone] = (orderMonthByPhone[order.phone] || 0) + order.totalPriceMarkup!;
              }
              if (orderDate >= quarterStart && orderDate <= quarterEnd) {
                orderCountByPhoneQuarter[order.phone] = (orderCountByPhoneQuarter[order.phone] || 0) + 1;
                orderQuarterByPhone[order.phone] = (orderQuarterByPhone[order.phone] || 0) + (order.totalPriceMarkup || 0);
              }
              if (orderDate >= orderDate51 && orderDate <= currentDate) {
                orderCountTotal51[order.phone] = (orderCountTotal51[order.phone] || 0) + 1;
              }
              if (orderDate >= orderDate112 && orderDate <= currentDate) {
                orderCountTotal112[order.phone] = (orderCountTotal112[order.phone] || 0) + 1;
              }
              orderCountTotal[order.phone] = (orderCountTotal[order.phone] || 0) + 1;
            }
          }
        });
        this.users = this.users.map((user) => {
          const orderCountMonth = orderCountMonthByPhone[user.phone] || 0; // Default to 0 if no orders for the user
          const orderMonth = orderMonthByPhone[user.phone] || 0; // Default to 0 if no orders for the user
          const orderQuarter = orderQuarterByPhone[user.phone] || 0; // Default to 0 if no orders for the user
          const orderCountQuarter = orderCountByPhoneQuarter[user.phone] || 0; // Default to 0 if no orders for the user
          const orderCountTotalName = orderCountTotal[user.phone] || 0;
          const orderCountTotal51Name = orderCountTotal51[user.phone] || 0;
          const orderCountTotal112Name = orderCountTotal112[user.phone] || 0;
          return {
            ...user,
            amountMonth: orderCountMonth, // Convert count to string as  is likely a string field
            amountQuarter: orderCountQuarter, // Convert count to string as  is likely a string field
            vikupMonth: orderMonth, // Convert count to string as  is likely a string field
            vikupQuarter: orderQuarter, // Convert count to string as  is likely a string field
            amountTotal: orderCountTotalName, 
            amountLast51: orderCountTotal51Name, 
            amountLast112: orderCountTotal112Name, 
          };
        });
        console.log(this.users);
        for (let user of this.users){
          if(user.name === 'Александров Евгений'){
            console.log(user);
          }
          if(user.isRetail){
            user.status = 'retail';
            user.statusRu = 'розница';
          }else if(user.amountTotal < 3){
            user.statusRu = 'Новые';
            user.status = 'new';
          }else if(user.amountLast51 > 2){
            user.statusRu = 'Лояльные';
            user.status = 'loyal';
          }else if(user.amountLast112 > 3){
            user.statusRu = 'В зоне риска';
            user.status = 'risk';
          }else{
            user.statusRu = 'В зоне потери';
            user.status = 'loss';
          }
        }
        this.usersSorted = [...this.users];
        console.log(this.users)
      }else{
        console.error("Заказы не вытянулись");
        this.errorMessage = 'Заказы не вытянулись';
      }
    });
    this.loading = false;
  }

  users: UserRFM[] = [];
  usersSorted: UserRFM[] = [];
  orders!: Order[];
  loading: boolean = true;
  errorMessage: string = '';

  sortField: string = 'created'; // Default sort field
  sortOrder: 'asc' | 'desc' = 'asc'; // Default sort order

  private statusOrder: { [key: string]: number } = {
    'Сохранён': 1,
    'В обработке у менеджера': 2,
    'Ожидает оплаты': 2,
    'Изготавливается': 3,
    'На складе': 4,
    'Отгружено': 5,
    // Add other statuses as needed
  };

  searchWord: string = '';
  
  search(){
    this.usersSorted = this.users.filter(item => {
      return Object.values(item).some(value => 
        String(value).toLowerCase().includes(this.searchWord.toLowerCase())
      )
    });
  }

   sort(field: string) {
      if (this.sortField === field) {
        // Toggle sort order if the same field is clicked
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        // Set new field and default to ascending order
        this.sortField = field;
        this.sortOrder = 'asc';
      }
      this.sortOrders();
    }
  
    private parseDate(dateString: string): Date {
      const parts = dateString.split('/');
      // Check if the dateString is in the expected format
      if (parts.length === 3) {
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1; // Months are 0-based in JavaScript
        const year = parseInt(parts[2], 10) + 2000; // Assuming YY is in the 21st century
        return new Date(year, month, day);
      }
      return new Date(NaN); // Return an invalid date if the format is incorrect
    }
    
  
    sortOrders() {
      this.usersSorted.sort((a, b) => {
        const aValue = a[this.sortField as keyof UserRFM];
        const bValue = b[this.sortField as keyof UserRFM];
    
        // Handle undefined values for the sort field
        if (aValue === undefined && bValue === undefined) {
          return 0; // Both are undefined, consider equal
        }
        if (aValue === undefined) {
          return this.sortOrder === 'asc' ? 1 : -1; // Treat undefined as greater
        }
        if (bValue === undefined) {
          return this.sortOrder === 'asc' ? -1 : 1; // Treat undefined as lesser
        }
    
        // Compare the primary sort values based on their types
        let comparison = 0;
        if (this.sortField === 'status') {
          // Use the custom order for status
          const aStatusOrder = this.statusOrder[aValue as string] || Number.MAX_VALUE; // Default to a high value if not found
          const bStatusOrder = this.statusOrder[bValue as string] || Number.MAX_VALUE; // Default to a high value if not found
          comparison = aStatusOrder - bStatusOrder; // Compare based on custom order
        }
        else if (this.sortField === 'created') {
          // If sorting by created, parse the date strings into Date objects
          const dateA = this.parseDate(aValue as string); // Convert to Date
          const dateB = this.parseDate(bValue as string); // Convert to Date
          comparison = dateA.getTime() - dateB.getTime();
        } else {
          // For other fields, use the existing comparison logic
          if (typeof aValue === 'string' && typeof bValue === 'string') {
            comparison = aValue.localeCompare(bValue);
          } else if (typeof aValue === 'number' && typeof bValue === 'number') {
            comparison = aValue - bValue;
          }
        }
    
        // Return the comparison result based on sort order
        return this.sortOrder === 'asc' ? comparison : -comparison;
      });
    }
}
