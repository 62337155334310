<head>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
</head>
<div class="page">
   @if(loadingOpt || loadingPrice){
    <h3>Загрузка</h3>
    <p class="error-message"> {{ errorMessage }} </p>
   }
   @else {
    <div class="black-page">
      <p class="error-message"> {{ errorMessage }} </p>
      <div class="main-options">
        <div class="label-select">
          <label for="typeL">Тип рейки <a target="_blank" href="https://vgdoor.ru/decorativ_lamelas/">см.</a></label>
          <select class='text-design' id="typeL" [(ngModel)]="typeL" (change)="resetOptions(1)">
            <option value="3side">3x сторон</option>
            <option value="4side">4x сторон</option>
            <option value="" disabled selected>----</option>
          </select>
        </div>
        
        
        @if(optionsCount >= 1){
          <div class="label-select">
            <label for="style">Вид декора </label>
            <select class='text-design' id="style" [(ngModel)]="style" (change)="resetOptions(2)">
              <option value="Матовый">Матовый</option>
              <option value="Древесный">Древесный</option>
              <option value="Глянцевый">Глянцевый</option>
              <option value="" disabled selected>----</option>
            </select>
          </div>
        }
        @if(optionsCount >= 2){
          <div class="label-select">
            <label for="decor">Декор 
              @if(decor){
                <a target="_blank"  href="{{ options.lamellaDecor.linkDep.entries[(decor)] }}">см.</a>}
              </label>
            <!-- <select class='text-design' id="decor" [(ngModel)]="decor" (change)="resetOptions(3)">
              @for (decor of options.lamellaDecor.entries; track $index) {
                @if(styleDep.entries[decor] == style){
                  <option> {{ decor }} </option>
                }
              }
              <option value="" disabled selected>----</option>
            </select> -->
            <form class="example-form">
              <input #decorHTML
                     type="text"
                     placeholder="----"
                     matInput
                     [formControl]="myControl"
                     [matAutocomplete]="auto"
                     [(ngModel)]="decor"
                     (input)="filter()"
                     (focus)="filter()"
                     class="filter-input"
                     >
              <mat-autocomplete requireSelection #auto="matAutocomplete" >
                @for (option of filteredOptions; track option) {
                  @if(styleDep.entries[option] == style){
                    <mat-option [value]="option">{{option}}</mat-option>
                  }
                }
              </mat-autocomplete>
          </form>
          </div>
        }
        @if(optionsCount >= 3){
          <div class="label-select">
            <label for="standart">Длина реек </label>
            <select class='text-design' id="standart" [(ngModel)]="standart" (change)="resetOptions(4)">
              <option value="std">До 2790мм</option>
              <option value="nstd">От 2791 до 3500мм</option>
              <option value="" disabled selected>----</option>
            </select>
          </div>
        }
        @if(optionsCount >= 4){
          <div class="label-select">
            <label for="plug">Заглушки </label>
            <select class='text-design' id="plug" [(ngModel)]="plug" (change)="resetOptions(5)" [disabled]="typeL == '4side'">
              <option value="1side">Требуются с 1 ст.</option>
              <option value="2side">Требуются с 2 ст.</option>
              <option value="0side">Не требуются</option>
              <option value="" disabled selected>----</option>
            </select>
          </div>
        }
      </div>
      <div class="number-options">
        <div class="label-input">
          <label for="height">Высота (мм)</label>
          <input class="number-input" id="height" [(ngModel)]="height" (input)="resetMessages()" (focus)="resetMessages()" onclick="this.select()"/>
        </div>
        <div class="label-input">
          <label for="width">Ширина (мм)</label>
          <input class="number-input" id="width" (input)="resetMessages()" (focus)="resetMessages()"  [(ngModel)]="width"onclick="this.select()"/>
        </div>
        <div class="label-input">
          <label for="length">Длина (мм)</label>
          <input class="number-input" id="length" (input)="resetMessages()" (focus)="resetMessages()"  [(ngModel)]="length"onclick="this.select()" [disabled]="(standart!=='nstd' && plug==='0side')||(!width || !height || !standart || !plug)"/>
        </div>
        <div class="label-input">
          <label for="amount">Кол-во</label>
          <input
            class="number-input red-border"
            id="amount"
            [(ngModel)]="amount" (input)="resetMessages()" (focus)="resetMessages()"
            onclick="this.select()"
          />
        </div>
        <div class="label-input">
          <label for="addorder" class="green added-order">{{addedOrder}}</label>
          <button id="addorder" (click)="addOrder()" class="add-order-button text-design" [disabled]="(standart=='nstd')"> 
            Добавить к заказу
          </button>
        </div>
        <div class="markup-block">
          <!-- <div class="inline-block">
            @if(statusCheck && !incogintoMode && person !== 'Сотрудник производителя мебели'){
              <div class="label-input label-markup">
                <label for="markup">Ваша скидка в %</label>
                <input
                  class="number-input markup-input"
                  id="markup"
                  onclick="this.select()" (input)="resetMessages(); markupCheck=true" 
                  (focus)="resetMessages(); markupCheck=true"
                  [(ngModel)]="markup"
                />
              </div>
              <div>
                <button class="text-design markup-button-2" (click)="doMarkup()">Применить скидку</button>
              </div>
              }
          </div>
          <div class="relative-markup">
              @if(statusCheck && !incogintoMode && person !== 'Сотрудник производителя мебели'){
                <button
                  class="markup-button text-design lower"
                  (click)="incogintoMode=!incogintoMode; markupCheck=true">Скрыть
                </button>
              }
              @if(statusCheck && incogintoMode && person !== 'Сотрудник производителя мебели'){
                <button
                  class="markup-button text-design lower"
                  (click)="incogintoMode=!incogintoMode; markupCheck=true">Показать
                </button>
              }
          </div> -->
          <div class="for-whom">
            @if(roleCheck){
              <div class="label-input label-markup">
                <label for="for-whom">Для кого</label>
                <input
                  class="number-input markup-input"
                  id="for-whom"
                  placeholder="80001234567"
                  onclick="this.select()" (input)="resetMessages()" (focus)="resetMessages()"
                  [(ngModel)]="phoneForWho"
                />
              </div>
            }
            @if(person !== 'Розничный покупатель' || roleCheck){
              <div class="label-input label-markup">
                <label for="use-points">Списать бонусы</label>
                <input
                  class="number-input markup-input"
                  id="use-points"
                  onclick="this.select()" (input)="resetMessages(); validateUsePoints($event)" (focus)="resetMessages()"
                  [(ngModel)]="usePoints"
                />
              </div>
            }
          </div>
        </div>
      <div>
        <p>@if (!width || !height || !standart || !plug) {
           @if (!width || !height){
            Укажите высоту и ширину 
           }@else {
            Сделайте выбор поля "заглушки"
           }
        }@else {
          @if(this.standart == 'std' && this.plug == '0side'){
             При данном выборе, стандартная длина 2790 мм 
          }@else {
             Возможная максимальная длина = {{ getMaxLength() }} 
          }
        }
        @if(typeL == '3side'){
          <span class="popup" href="#"> (см.) <img src="https://storage.yandexcloud.net/fabfas/Website/3side.png" width="700" height="400"/></span>
        }@else if(typeL == '4side'){
          <span class="popup"> (см.) <img src="https://storage.yandexcloud.net/fabfas/Website/4side.png" width="700" height="400"/></span>

        }
        <!-- <a target="_blank" href="https://storage.yandexcloud.net/fabfas/Website/lamella_tech_inf.png"> см.</a> -->
      </p>
      </div>
      </div>
      <div></div>
      <div class="comment-options">
      <div class="label-input">
        <label for="msgCompany" class="label-padding">Комментарий для менеджера</label>
        <textarea
          class="comment-input"
          type="text"
          id="msgCompany"
          placeholder="Укажите свой комментарий к заказу. Например: «До заказ к заказу …» или «Нужна будет доставка по адресу …», или «Самовывоз»"
          maxlength="200"
          [(ngModel)]="messageForCompany"
        ></textarea>
      </div>
      @if(statusCheck){
        <div class="label-input-comment">
          <label for="msgClient" class="label-padding">Комментарий о вашем заказчике</label>
          <textarea
            class="comment-input"
            type="text"
            id="msgClient"
            maxlength="200"
            placeholder="Для удобства дальнейшего поиска укажите ФИО вашего заказчика или номер вашего договора с заказчиком"
            [(ngModel)]="messageForClient"
          ></textarea>
        </div>
      }
      @if(person !== 'Сотрудник производителя мебели'){
        <div class="payment">
          <div>
            <label for="paymentPlan">Планируемая форма оплаты</label>
            <select class='text-design payment-select' id="paymentPlan" [(ngModel)]="paymentPlanForm">
              @for (opt of ['Наличные, как физическое лицо','Картой физического лица','Перевод на счёт с карты физического лица','Расчётный счёт от юридического лица без НДС', 'Расчётный счёт от юридического лица c НДС']; track $index) {
                <option> {{ opt }} </option>
              }
              <option selected disabled value="">----</option>
            </select>
          </div>
          <div>
            <button class="save-order text-design" (click)="createOrder('Сохранён')">
              Сохранить заказ в черновик
            </button>
          </div>
        </div>
        <div class="payment payment-date">
          <div>
            <label for="paymentDate">Планируемая дата оплаты</label>
            <input [ngModel]="paymentDate | date:'yyyy-MM-dd'"
            (ngModelChange)="paymentDate = $event"
            type="date" id="paymentDate" class="input-date" value="{{paymentDate}}"/>
          </div>
          <div>
            <button class="create-order text-design" (click)="createOrder('В обработке у менеджера')">
              Получить счёт
            </button>
          </div>
        </div>
      }@else{
        <div class="payment">
          <div style="visibility: hidden;">
            <label for="paymentPlan">Планируемая форма оплаты</label>
            <select class='text-design payment-select' id="paymentPlan" [(ngModel)]="paymentPlanForm">
              @for (opt of ['Наличные, как физическое лицо','Картой физического лица','Перевод на счёт с карты физического лица','Расчётный счёт от юридического лица без НДС', 'Расчётный счёт от юридического лица c НДС']; track $index) {
                <option> {{ opt }} </option>
              }
              <option selected disabled value="">----</option>
            </select>
          </div>
          <div>
            <button class="save-order text-design" (click)="createOrder('Сохранён')">
              Сохранить заказ
            </button>
          </div>
        </div>
      }
      </div>
    </div>
    <div class="white-page">
      <div class="done-place">
        <div class="done-helper">
          <table>
            <tr>
              <td class="pending-data-no-border">
                <p>№</p>
              </td>
              <td class="pending-data-no-border">
                <p>Тип рейки</p>
              </td>
              <!-- <td class="pending-data-no-border">
                <p>Длина реек</p>
              </td> -->
              <td class="pending-data-no-border">
                <p>Заглушки</p>
              </td>
              <td class="pending-data-no-border data-short">
                <p>Декор</p>
              </td>
              <td class="pending-data-no-border data-short">
                <p>Высота</p>
              </td>
              <td class="pending-data-no-border data-short">
                <p>Ширина</p>
              </td>
              <td class="pending-data-no-border data-short">
                <p>Длина</p>
              </td>
              <td class="pending-data-no-border data-short">
                <p>Кол-во</p>
              </td>
              <td class="pending-data-no-border data-short">
                <p>Розничная цена</p>
              </td>
              @if(statusCheck){
                <td class="pending-data-no-border data-short">
                  <p>Розничная сумма</p>
                </td>
                <td class="pending-data-border-right data-short">
                  <p>Оптовая сумма</p>
                </td>  
              }@else {
                <td class="pending-data-border-right data-short">
                  <p>Розничная сумма</p>
                </td>
              }
              
              <!-- @if (statusCheck && !incogintoMode && markupCheck) {
              <td class="pending-data-border-left data-short">
                <p>Сумма РРЦ с %</p>
              </td>
              } -->
            </tr>
            @for (sub of doneOrders; track $index) {
            <tr>
              <!-- 
                {{ $index+1}}
                 -->
              <td class="pending-data-no-border-sub">
                <span style="text-wrap: nowrap;">
                  <button class="empty-button" (click)="moveSuborder($index, 'up')" [disabled]="$index === 0">
                    <i class="fa fa-sort-up"></i>
                  </button>
                  {{ $index+1 }}
                  <button class="empty-button" (click)="moveSuborder($index, 'down')" [disabled]="$index === doneOrders.length- 1">
                    <i class="fa fa-sort-down"></i>
                  </button>
                </span>
                <span matTooltip="Скопировать" matTooltipPosition="above" (click)="copy($index)">
                  <i class="fas fa-copy copy-icon"></i> 
                </span>
              </td>
              <td class="pending-data-no-border-sub data-short">
                {{ MAP_TYPE[sub.typeL] }}
              </td>
              <!-- <td class="pending-data-no-border-sub data-short">
                {{ MAP_STANDART[sub.standart] }}
              </td> -->
              <td class="pending-data-no-border-sub data-short">
                {{ MAP_PLUG[sub.plug] }}
              </td>
              <td class="pending-data-no-border-sub data-short">
                {{ sub.decor }}
              </td>
              <td class="pending-data-no-border-sub data-short">
                  {{ sub.height | number }}
              </td>
              <td class="pending-data-no-border-sub data-short">
                  {{ sub.width | number}}
              </td>
              <td class="pending-data-no-border-sub data-short">
                {{ sub.length | number}}
              </td>
              <td class="pending-data-no-border-sub data-short">
                {{ sub.amount }}
              </td>
              <td class="pending-data-no-border-sub data-short">
                @if(incogintoMode){
                  {{ ceil(sub.price * (1 + markup / 100)) }}
                }@else {
                  {{ sub.price }}
                }
              </td>
              @if(statusCheck){
                <td class="pending-data-no-border-sub data-short">
                  {{ sub.priceCalc | number}}
                </td>
                <td class="pending-data-border-right-sub data-short">
                  {{ sub.priceMarkup | number}}
                </td>
              }@else{
                <td class="pending-data-border-right-sub data-short">
                  {{ sub.priceCalc | number}}
                </td>
              }
              <!-- @if(statusCheck && !incogintoMode && markupCheck){
                <td class="pending-data-border-left-sub data-short"> {{ sub.priceMarkup | number }} </td>
              } -->
              <button
                mat-mini-fab
                class="delete-button"
                (click)="removeSuborder($index)"
              >
                <img src="\assets\cross.png" width="25" height="25" />
              </button>
            </tr>
            }
            <tr>
              <td class="pending-data-no-border-sub" style="font-weight: bold;">Итого</td>
              <td class="pending-data-no-border-sub"></td>
              <!-- <td class="pending-data-no-border-sub"></td> -->
              <td class="pending-data-no-border-sub"></td>
              <td class="pending-data-no-border-sub"></td>
              <td class="pending-data-no-border-sub"></td>
              <td class="pending-data-no-border-sub"></td>
              <td class="pending-data-no-border-sub"></td>
              <td class="pending-data-no-border-sub"> {{ totalAmount }}</td>
              <td class="pending-data-no-border-sub"></td>
              @if(statusCheck){
                <td class="pending-data-no-border-sub"></td>
                <td class="pending-data-border-right-sub" [ngClass]="{'notify': (usePoints > 0)}" [title]="usePoints>0?'Использовано ' +usePoints+ ' бонусов':''" >{{ (totalPriceMarkup - usePoints) | number }}</td>
              }@else{
                <td class="pending-data-border-right-sub">{{ totalPrice | number }}</td>
              }
            </tr>
          </table>
        </div>
      </div>
  </div>
  }
 </div>
