<head>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
</head>
<div class="white-page">
  @if (loading) {
  <h2>Загрузка данных</h2>
  } @else if(errorMessage){
    <h2>{{errorMessage}}</h2>
  } @else {
    @if(pointsHistory.length == 0){
      <h2>История бонусов отсутствует</h2>
    }@else{
      <div class="border-radius">
        <table>
          <thead>
            <tr>
              <th>№</th>
              <th>Количество</th>
              <th>Тип</th>
              <th>Номер заказа</th>
              <th>Дата</th>
            </tr>
          </thead>
          <tbody>
            @for (entry of pointsHistory; track $index) {
              <tr>
                <td>{{$index+1}}</td>
                <td>{{entry.amount}}</td>
                <td>{{entry.operationType}}</td>
                <td>
                  <a href="/order/{{entry.orderID}}">
                    @if(entry.orderBitrixID){
                      {{entry.orderBitrixID}}
                    }@else {
                      {{entry.orderID}}
                    }
                  </a>
                </td>
                <td>{{entry.date}}</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    }
  }
</div>
