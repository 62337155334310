import { Component } from '@angular/core';
import { ApiService } from '../services/api.service';
import {
  Options,
  collectLUX,
  collectPVC,
  decorLUX,
  decorPVC,
  ispol,
  kromka,
  lamellaDecor,
  milling,
  polish,
  star,
  thickness,
} from '../interfaces/options';
import { KeysPipe } from '../pipes/keys.pipe';
import { FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { Price } from '../interfaces/price';
import { AuthService } from '../services/auth.service';

interface DepToHelpInterface {
  [key: string]: string;
}

interface tmpInt {
  [key: string]: string[];
}

interface luxKromkaInt {
  [key: string]: number;
}

interface luxCollectInt {
  [key: string]: luxKromkaInt;
}


interface arrayAsAnyInterface {
  [key: string]: tmpInt;
}
interface arrayAsAnyLUXInterface {
  [key: string]: luxCollectInt;
}

@Component({
  selector: 'app-cabinet',
  standalone: true,
  imports: [KeysPipe, FormsModule, NgClass],
  templateUrl: './cabinet.component.html',
  styleUrl: './cabinet.component.scss',
})
export class CabinetComponent {
  constructor(private apiService: ApiService, private authService: AuthService) {
    apiService.getOptions().subscribe((data: Options) => {
      if ('message' in data) {
        //@ts-ignore
        this.errorMessage = JSON.stringify(data.message);
        return;
      }
      if (data) {
        console.log(data);
        this.loadingOpt = false;
        this.errorMessage = '';
        this.options = data;
      } else {
        this.errorMessage = 'Произошла ошибка.';
      }
    });
    apiService.getPrice().subscribe((data: Price) => {
      if ('message' in data) {
        //@ts-ignore
        this.errorMessage = JSON.stringify(data.message);
        return;
      }
      if (data) {
        this.loadingPrice = false;
        this.errorMessage = '';
        this.price = data;
      } else {
        this.errorMessage = 'Произошла ошибка.';
      }
    });
    authService.setCabinetGuardCheck();
  }

  helper: string[] = []; //надо потому что ts ругается в хтмле
  mapDepToHelp: DepToHelpInterface = {};
  arrayAsAny: arrayAsAnyInterface = {}; //надо потому что ts ругается в хтмле
  arrayAsAnyPrice: arrayAsAnyLUXInterface = {}; //
  curRender: string = '';
  checkRender: boolean = false;
  checkPrice: boolean = false;
  checkLUX: boolean = false;

  addName: string = '';
  catalogValue: string = 'mono';
  groupValue: string = '1';

  millingFilter: string = '';
  decorPVCFilter: string = '';
  decorLUXFilter: string = '';

  errorMessage: string = 'Загрузка';
  message: string = '';
  loadingOpt: boolean = true;
  loadingPrice: boolean = true;

  previous: string = '';

  options!: Options;
  price!: Price;
  curOptions!:
    | thickness
    | collectPVC
    | collectLUX
    | milling
    | decorPVC
    | decorLUX
    | kromka
    | ispol
    | star
    | polish
    | lamellaDecor;

  renderToRus: DepToHelpInterface = {
    thickness: 'Толщина',
    collectPVC: 'Коллекции ПВХ',
    collectLUX: 'Коллекции LUX',
    milling: 'Фрезеровка',
    decorPVC: 'Декор ПВХ',
    decorLUX: 'Декор LUX',
    ispol: 'Вид фасада',
    star: 'Эффекты старения',
    polish: 'Лак',
    kromka: 'Кромка',
    lamellaDecor: 'Декор реек',
  };
  renderToRusDecline: DepToHelpInterface = {
    thickness: 'толщины',
    collectPVC: 'коллекции ПВХ',
    collectLUX: 'коллекции LUX',
    milling: 'фрезеровки',
    decorPVC: 'декора ПВХ',
    decorLUX: 'декора LUX',
    ispol: 'вида фасада',
    star: 'эффекта старения',
    polish: 'лака',
    kromka: 'кромки',
    lamellaDecor: 'декора рейки',
  };

  depToRus: DepToHelpInterface = {
    thicknessDep: 'толщины',
    collectDep: 'коллекции',
    millingDep: 'фрезеровки',
    frezaDep: 'фрезы',
    starDep: 'старения',
    catalogDep: 'каталога',
    priceDep: 'цены',
    priceMultDep: 'умножение/прибавка',
    groupDep: 'группы',
    linkDep: 'ссылки',
    styleDep: 'стиля',
    ispolDep: 'вида фасада',
    integraDep: 'интегры',
    minHeightDep: 'минимальной высоты',
    minWidthDep: 'минимальной ширины',
    maxHeightDep: 'максимальной высоты',
    maxWidthDep: 'максимальной ширины',
  };

  renderOptions(name: string) {
    this.blankMessage();
    if (this.checkRender) {
      if (!confirm('Несохранённые изменения будут сброшены')) {
        return;
      }
    }
    this.decorLUXFilter = '';
    this.decorPVCFilter = '';
    this.millingFilter = '';
    this.checkPrice = false;
    this.curRender = name;
    this.previous = '';
    this.checkRender = true;
    this.curOptions = structuredClone(this.options[name]);
    this.helper = [];
    this.mapDepToHelp = {};
    this.arrayAsAny = {};
    //для каждого поля опций текущих
    for (const key of Object.keys(this.curOptions)) {
      if (key === 'entries' || key === 'help') continue;
      //THIS IS ONLY POSSIBLE SCENARIO
      //в помощник добавляется название зависимости этого поля
      this.helper.push((this.curOptions[key] as any).helpDep);
      //для рендера в хтмле
      this.arrayAsAny[key] = {};
      //зависимость превращается в поле
      this.mapDepToHelp[(this.curOptions[key] as any).helpDep] = (
        this.curOptions[key] as any
      ).help;
      for (const entry of Object.keys((this.curOptions[key] as any).entries)) {
        this.arrayAsAny[key][entry] = (this.curOptions[key] as any).entries[
          entry
        ];
      }
    }
    // this.logAll();
    // this.logAll();
  }

  renderPrice(name: string) {
    if (this.checkRender) {
      if (!confirm('Несохранённые изменения будут сброшены')) {
        return;
      }
    }
    this.checkRender = true;
    this.curRender = name;
    console.log(this.curRender);
    
    if (name === 'priceLUX') {
      this.arrayAsAnyPrice = {};
      // for(const thick of Object.keys(this.price.priceLUX.thickness)){
      for(const thick of this.options.thickness.entries){
        //@ts-ignore
        // for (const collect of Object.keys(this.price.priceLUX.thickness[thick].collect)){
          // console.log(thick);
          this.arrayAsAnyPrice[thick] = {};
        for (const collect of this.options.collectLUX.entries){
          // console.log(collect);
          if(!this.options.collectLUX.thicknessDep.entries[collect].includes(thick)){
            // console.log(thick + " " + collect);
            continue;
          }
          this.arrayAsAnyPrice[thick][collect] = {};
          // console.log(this.arrayAsAny);
          //@ts-ignore
          if(!this.price.priceLUX.thickness[thick] || !this.price.priceLUX.thickness[thick].collect[collect]){
            // console.log('costyl');
            this.arrayAsAnyPrice[thick][collect]['Без кромки'] = 0;  
            this.arrayAsAnyPrice[thick][collect]['Кромка в цвет'] = 0;  
            this.arrayAsAnyPrice[thick][collect]['Дизайн кромка'] = 0;  
            this.arrayAsAnyPrice[thick][collect]['С трёх сторон'] = 0;  
            continue;
          }
          // console.log(this.arrayAsAny);
          this.arrayAsAnyPrice[thick][collect]['Без кромки'] = 
          //@ts-ignore
          this.price.priceLUX.thickness[thick].collect[collect].kromka['Без кромки'];
          this.arrayAsAnyPrice[thick][collect]['Кромка в цвет'] = 
          //@ts-ignore
          this.price.priceLUX.thickness[thick].collect[collect].kromka['Кромка в цвет'];
          this.arrayAsAnyPrice[thick][collect]['Дизайн кромка'] = 
          //@ts-ignore
          this.price.priceLUX.thickness[thick].collect[collect].kromka['Дизайн кромка'];
          this.arrayAsAnyPrice[thick][collect]['С трёх сторон'] = 
          //@ts-ignore
          this.price.priceLUX.thickness[thick].collect[collect].kromka['С трёх сторон'];
        }
      }
      this.checkLUX = true;
    } else {
      this.arrayAsAnyPrice = {};
      for(const thick of this.options.thickness.entries){
        this.arrayAsAnyPrice[thick] = {};
        //@ts-ignore
        for (const collect of this.options.collectPVC.entries){
          if(!this.options.collectPVC.thicknessDep.entries[collect].includes(thick)){
            // console.log(thick + " " + collect);
            continue;
          }
          this.arrayAsAnyPrice[thick][collect] = {};
          //@ts-ignore
          if(!this.price.pricePVC.thickness[thick] || !this.price.pricePVC.thickness[thick].collect[collect]){
            this.arrayAsAnyPrice[thick][collect]['1'] = 0;  
            this.arrayAsAnyPrice[thick][collect]['2'] = 0;  
            this.arrayAsAnyPrice[thick][collect]['3'] = 0;  
            this.arrayAsAnyPrice[thick][collect]['4'] = 0;  
            this.arrayAsAnyPrice[thick][collect]['5'] = 0;  
            continue;
          }
          //@ts-ignore
          this.arrayAsAnyPrice[thick][collect]['1'] = 
          //@ts-ignore
          this.price.pricePVC.thickness[thick].collect[collect].group['1'];
          //@ts-ignore
          this.arrayAsAnyPrice[thick][collect]['2'] = 
          //@ts-ignore
          this.price.pricePVC.thickness[thick].collect[collect].group['2'];
          //@ts-ignore
          this.arrayAsAnyPrice[thick][collect]['3'] = 
          //@ts-ignore
          this.price.pricePVC.thickness[thick].collect[collect].group['3'];
          //@ts-ignore
          this.arrayAsAnyPrice[thick][collect]['4'] = 
          //@ts-ignore
          this.price.pricePVC.thickness[thick].collect[collect].group['4'];
          //@ts-ignore
          this.arrayAsAnyPrice[thick][collect]['5'] = 
          //@ts-ignore
          this.price.pricePVC.thickness[thick].collect[collect].group['5'];
        }
      }
      this.checkLUX = false;
    }
    //[(ngModel)]="price.priceLUX.thickness[thickness].collect[item].kromka['Без кромки']"
    console.log(this.price);
    console.log(this.arrayAsAnyPrice);
    //@ts-ignore
    // console.log(this.price.pricePVH.thickness["19"].collect["Standart"].group['1']);
    
    this.checkPrice = true;
    // for (const iterator of this.options.thickness.entries) {
    //   if (iterator === '18') {
    //     continue;
    //   }
    //   if (!this.price.pricePVH.thickness[iterator]) {
    //     //@ts-ignore
    //     this.price.pricePVH.thickness[iterator] = { collect: {} };
    //   }
    //   for (const iterator2 of this.options.thickness.collectDep.entries[
    //     iterator
    //   ]) {
    //     if (!this.price.pricePVH.thickness[iterator]['collect'][iterator2]) {
    //       this.price.pricePVH.thickness[iterator]['collect'][iterator2] = {
    //         //@ts-ignore
    //         group: {},
    //       };
    //       for (const iterator3 of ['1', '2', '3', '4', '5']) {
    //         //@ts-ignore
    //         this.price.pricePVH.thickness[iterator]['collect'][iterator2][
    //           'group'
    //         ][iterator3] = 0;
    //       }
    //     }
    //   }
    // }
    // console.log(this.price);
  }
  //Добавить к типу (толщина, декор...) ещё один элемент
  addPosition(name: string) {
    this.logAll();
    this.blankMessage();
    if (!name) {
      this.errorMessage = 'Введите название для новой позиции';
      return;
    }
    if (this.curOptions.entries.includes(name)) {
      this.errorMessage = 'Такая позиция уже существует';
      return;
    }
    this.curOptions.entries.push(name);
    // this.curOptions.entries.sort();
    for (const key of Object.keys(this.curOptions)) {
      if (key === 'entries' || key === 'help') continue;
      //THIS IS ONLY POSSIBLE SCENARIO
      (this.curOptions[key] as any).entries[name] = [];
      // this.arrayAsAny[key][name] = (this.curOptions[key] as any).entries[name];
      // this.arrayAsAny[key][name] = (this.curOptions[key] as any).entries[name];
      if(this.previous){
        //@ts-ignore
        this.arrayAsAny[key][name] = structuredClone(this.curOptions[key].entries[this.previous]);
        // console.log(this.curOptions);
        //@ts-ignore
        this.curOptions[key].entries[name] = this.arrayAsAny[key][name];
        
      }else{
        this.arrayAsAny[key][name] = (this.curOptions[key] as any).entries[name];
      }
      this.logAll();
      

    }
    //@ts-ignore
    if(this.curRender == 'milling' && this.millingFilter && !this.curOptions.collectDep.entries[name][0]){
      //@ts-ignore
      this.curOptions.collectDep.entries[name].push(this.millingFilter);
    }
    //@ts-ignore
    if(this.curRender == 'decorPVC' && this.decorPVCFilter && !this.curOptions.groupDep.entries[name][0]){
      //@ts-ignore
      this.curOptions.groupDep.entries[name].push(this.decorPVCFilter);
    }
    //@ts-ignore
    if(this.curRender == 'decorLUX' && this.decorLUXFilter && !this.curOptions.collectDep.entries[name][0]){
      //@ts-ignore
      this.curOptions.collectDep.entries[name].push(this.decorLUXFilter);
    }
    this.previous = name;



    // if (this.curOptions.help === 'decorLUX') {
    //   //@ts-ignore
    //   this.curOptions.catalogDep.entries[name] = 'mono';
    //   //@ts-ignore
    //   this.options.decorLUX.catalogDep.entries[name] = 'mono';
    //   console.log(this.curOptions.catalogDep);
    //   //@ts-ignore
    //   console.log(this.curOptions.catalogDep.entries);
    //   // this.options.decor.groupDep.entries[name] = '1';
    //   // this.options.decor.catalogDep.entries[name] = 'mono';
    //   //@ts-ignore
    //   // this.curOptions.groupDep.entries[name] = [];
    //   //@ts-ignore
    //   // this.curOptions.groupDep.entries[name].push('1');
    //   //@ts-ignore
    //   // this.curOptions.catalogDep.entries[name] = [];
    //   // @ts-ignore
    //   // this.curOptions.catalogDep.entries[name].push('mono');
    // }
  }

  deletePosition(key: string) {
    this.blankMessage();
    if ( !confirm('Вы действительно хотите удалить?')) {
      return;
    }
    this.removeItemOnce(this.curOptions.entries, key);
    for (const dep of Object.keys(this.curOptions)) {
      if (dep === 'entries' || dep === 'help') continue;
      delete (this.curOptions[dep] as any).entries[key];
    }

    //надо менять бэк ради этой хуеты. Похуй. или не надо
    for (const key1 of Object.keys(this.options)) {
      if (key1 === this.curOptions.help || key1.includes('_')) {
        continue;
      }
      for (const dep of Object.keys(this.options[key1])) {
        if (!dep.includes('Dep')) {
          continue;
        }
        //@ts-ignore it exists.
        if (this.options[key1][dep].help === this.curOptions.help) {
          //@ts-ignore for the 100-th time. It exists.
          for (const k in this.options[key1][dep].entries) {
            //@ts-ignore
            this.removeItemOnce(this.options[key1][dep].entries[k], key);
          }
        }
      }
    }
  }

  checkFilter(optToCheck: string, what: string): boolean{
    switch(what){
      case 'milling':
        //@ts-ignore
        if(this.millingFilter && this.curOptions.collectDep.entries[optToCheck] != this.millingFilter){
          return false;
        }
        break;
      case 'decorPVC':
        //@ts-ignore
        if(this.decorPVCFilter && this.curOptions.groupDep.entries[optToCheck] != this.decorPVCFilter){
          return false;
        }
        break;
      case 'decorLUX':
        //@ts-ignore
        if(this.decorLUXFilter && this.curOptions.collectDep.entries[optToCheck] != this.decorLUXFilter){
          return false;
        }
        break;
    }
    return true;
  }

  pushChanges() {
    // this.logAll();
    this.blankMessage();
    if ( !confirm('Вы действительно хотите внести изменения?')) {
      return;
    } 
    if (this.curOptions.entries.length === 0) {
      this.errorMessage = 'Список не может быть пустым';
      return;
    }
    let objSet = {};
    this.curOptions.entries.sort();
    //@ts-ignore ну типа можно прописать index signature но на кой хуй это нам надо то блядь
    objSet[this.curOptions.help] = this.curOptions;
    this.authService.removeCabinetGuardCheck();
    for (const key1 of Object.keys(this.options)) {
      if (key1 === this.curOptions.help || key1.includes('_')) {
        continue;
      }
      for (const dep of Object.keys(this.options[key1])) {
        if (!dep.includes('Dep')) {
          continue;
        }
        //@ts-ignore it exists.
        if (this.options[key1][dep].help === this.curOptions.help) {
          //@ts-ignore
          objSet[this.options[key1].help] = this.options[key1];
        }
      }
    }
    for (const objKey of Object.keys(objSet)) {
      let obj = {};
      //@ts-ignore ну типа можно прописать index signature но на кой хуй это нам надо то блядь
      obj[objKey] = objSet[objKey];

      this.apiService.updateOptions(obj).subscribe((data: any) => {
        if ('message' in data) {
          if (!data.message.includes('обновл')) {
            this.errorMessage = JSON.stringify(data.message);
            return;
          }
        }
        this.message = 'Опции обновлены';
        this.checkRender = false;
        this.loadingOpt = true;
        this.apiService.getOptions().subscribe((dataGet: Options) => {
          if ('message' in dataGet) {
            //@ts-ignore
            this.errorMessage = JSON.stringify(dataGet.message);
            return;
          }
          if (dataGet) {
            this.loadingOpt = false;
            this.errorMessage = '';
            this.options = dataGet;
          } else {
            this.errorMessage = 'Произошла ошибка.';
          }
        });
      });
    }
  }

  pushPrices() {
    this.blankMessage();
    if ( !confirm('Вы действительно хотите внести изменения?')) {
      return;
    }
    let obj: any = {};
    if (this.checkLUX) {
      obj['priceLUX'] = { thickness: {} };
      for (const thick of Object.keys(this.arrayAsAnyPrice)){
        obj['priceLUX'].thickness[thick] = { collect:{} };
        for (const coll of Object.keys(this.arrayAsAnyPrice[thick])){
          obj['priceLUX'].thickness[thick].collect[coll] = { kromka:{} };
          obj['priceLUX'].thickness[thick].collect[coll].kromka =  
          this.arrayAsAnyPrice[thick][coll];
        }
      }
    } else {
      obj['pricePVC'] = { thickness: {} };
      for (const thick of Object.keys(this.arrayAsAnyPrice)){
        obj['pricePVC'].thickness[thick] = { collect:{} };
        for (const coll of Object.keys(this.arrayAsAnyPrice[thick])){
          obj['pricePVC'].thickness[thick].collect[coll] = { group:{} };
          obj['pricePVC'].thickness[thick].collect[coll].group =  
          this.arrayAsAnyPrice[thick][coll];
        }
      }
    }
    console.log(obj);
    console.log(this.arrayAsAnyPrice);
    // return;
    this.apiService.updatePrice(obj).subscribe((data: any) => {
      if ('message' in data) {
        if (!data.message.includes('обновл')) {
          this.errorMessage = JSON.stringify(data.message);
          return;
        }
      }
      this.message = 'Прайс обновлен';
      this.checkRender = false;
      this.loadingPrice = true;
      this.apiService.getPrice().subscribe((dataGet: Price) => {
        if ('message' in dataGet) {
          //@ts-ignore
          this.errorMessage = JSON.stringify(dataGet.message);
          return;
        }
        if (dataGet) {
          this.loadingPrice = false;
          this.errorMessage = '';
          this.price = dataGet;
        } else {
          this.errorMessage = 'Произошла ошибка.';
        }
      });
    });
  }

  changeState(opt: string, key: string, dep: string) {
    this.blankMessage();
    if ((this.curOptions[key] as any).entries[opt].includes(dep)) {
      this.removeItemOnce((this.curOptions[key] as any).entries[opt], dep);
    } else {
      (this.curOptions[key] as any).entries[opt].push(dep);
    }
  }

  // changeSelect(opt: string, dep: string){

  // }

  // groupChange(key: string) {
  //   //@ts-ignore
  //   this.curOptions.groupDep.entries[key] = [];
  //   //@ts-ignore
  //   this.curOptions.groupDep.entries[key].push(this.arrayAsAny.groupDep[key]);

  //   // this.logAll();
  // }
  // catalogChange(key: string) {
  //   //@ts-ignore
  //   this.curOptions.catalogDep.entries[key] = [];
  //   //@ts-ignore
  //   this.curOptions.catalogDep.entries[key].push(this.arrayAsAny.catalogDep[key]);
    
  //   // this.logAll();
  // }
  
  //Для select, чтобы изменять текущее значение
  universalChange(dep: string, key: string | string[]){
    //@ts-ignore
    this.curOptions[dep].entries[key] = [];
    //@ts-ignore
    this.curOptions[dep].entries[key].push(this.arrayAsAny[dep][key]);
    // console.log(key);
    
    // this.logAll();
  }

  private removeItemOnce(
    arr: any[],
    value: string | number
  ): string[] | number[] {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }
  private blankMessage() {
    this.errorMessage = '';
    this.message = '';
  }
  private logAll() {
    console.log(this.options);
    console.log(this.arrayAsAny);
    console.log(this.helper);
    console.log(this.mapDepToHelp);
    console.log(this.curOptions);
  }
}
