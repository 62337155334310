import { Component } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { AsyncPipe } from '@angular/common';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import {
  BreakpointObserver,
  Breakpoints,
  LayoutModule,
} from '@angular/cdk/layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatNavList } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';

// import { Observable, map, shareReplay } from 'rxjs';

import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { HandsetService } from '../services/handset.service';
@Component({
  selector: 'app-shell',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatToolbarModule,
    MatNavList,
    MatIconModule,
    NgIf,
    LayoutModule,
    RouterModule,
    AsyncPipe,
    MatButtonModule,
    MatListModule,
    NgClass,
    MatButtonModule,
    MatMenuModule
  ],
  templateUrl: './shell.component.html',
  styleUrl: './shell.component.scss',
})
export class ShellComponent {
  // isHandset$: Observable<boolean> = this.breakpointObserver
  //   .observe([Breakpoints.Handset])
  //   .pipe(
  //     map((result) => result.matches),
  //     shareReplay()
  //   );
  constructor(
    // private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    public router: Router,
    public handset: HandsetService
  ) {
    this.isAuthenticated = authService.isAuthenticated();
    authService.getCookie().subscribe((user)=>{
      console.log('Частое обновление shella?');
      console.log(this.points);
      if(!user.points){
        this.points = 0;
      }else{
        this.points = user.points;
      }
      if(!user.discount){
        this.discount = 0;
      }else{
        this.discount = user.discount;
      }
      
      this.isCompany = user.company !== 'Розничный покупатель';
      this.isAuthorizedManager = user.role !== 'Пользователь';
      this.isAuthorizedOwner = user.role == 'Админ' || user.role == 'Владелец';
      console.log(user);
    });
  }
  points: number = 0;
  discount: number = 0;
  isCompany: boolean = false;
  isAuthenticated: boolean = false;
  isAuthorizedOwner: boolean = false;
  isAuthorizedManager: boolean = false;

  isMobile = localStorage.getItem('mobile');
}


// @if(!(handset.isHandset$ | async)){
//   <a mat-button class="logo" routerLink="/404">Опа</a>
// }