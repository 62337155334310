import { Component } from '@angular/core';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { pointsHistory } from '../interfaces/user';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-points-user',
  standalone: true,
  imports: [],
  templateUrl: './points-user.component.html',
  styleUrl: './points-user.component.scss'
})
export class PointsUserComponent {
  constructor(private apiService: ApiService, private authService: AuthService, private activatedRoute: ActivatedRoute) {
    apiService.getUserPoints(activatedRoute.snapshot.params['id']).subscribe((data: any)=>{
      if(data && "pointsHistory" in data){
        this.pointsHistory = data.pointsHistory;
      }
      else{
        this.pointsHistory = [];
        this.errorMessage = 'Пользователь не найден';
      }
      this.loading = false;
    });
  }
  //@ts-ignore
  pointsHistory: pointsHistory[];
  loading: boolean = true;
  errorMessage: string = '';
}
