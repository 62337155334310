import { Injectable, inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from './api.service';
import { jwtDecode } from 'jwt-decode';

import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { User } from '../interfaces/user';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private cookieService: CookieService,
    private apiService: ApiService,
    private router: Router
  ) {}
  private refreshing: boolean = false;
  private pendingRequests: Array<() => void> = [];

  isAuthenticatedVar: boolean = false;

  isAuthenticated(): boolean {
    return !!localStorage.getItem('session');
  }

  // isAuthorizedOwner(): boolean {
  //   if (!this.isAuthenticated()) {
  //     return false;
  //   }
  //   return (
  //     localStorage.getItem('session') == 'Админ' ||
  //     localStorage.getItem('session') == 'Владелец'
  //   );
  // }

    // if (this.getCookie() && 'UserInfo' in jwtDecode(this.getCookie())) {
    //   return (
    //     //TODO check properly for cookie. If none, logout.
    //     //@ts-ignore If not authorized there is no cookie if authorized there should be cookie
    //     jwtDecode(this.getCookie()).UserInfo.role === 'Админ' ||
    //     //@ts-ignore If not authorized there is no cookie if authorized there should be cookie
    //     jwtDecode(this.getCookie()).UserInfo.role === 'Владелец'
    //   );
    // } else {
    //   return false;
    // }

  setOrderGuardCheck(): void{
    localStorage.setItem('orderGuardCheck', '1');
  }

  getOrderGuardCheck(): boolean{
    return localStorage.getItem('orderGuardCheck') === '1';
  }

  removeOrderGuardCheck(): void{
    localStorage.removeItem('orderGuardCheck');
  }
  
  setCabinetGuardCheck(): void{
    localStorage.setItem('cabinetGuardCheck', '1');
  }

  getCabinetGuardCheck(): boolean{
    return localStorage.getItem('cabinetGuardCheck') === '1';
  }

  removeCabinetGuardCheck(): void{
    localStorage.removeItem('cabinetGuardCheck');
  }

  // isAuthorizedManager(): boolean {
  //   if (!this.isAuthenticated()) {
  //     return false;
  //   }
  //   return localStorage.getItem('session') !== 'Пользователь';
  // }
    // if (this.getCookie && 'UserInfo' in jwtDecode(this.getCookie())) {
    //   return (
    //     //TODO check properly for cookie. If none, logout.
    //     //@ts-ignore If not authorized there is no cookie if authorized there should be cookie
    //     jwtDecode(this.getCookie()).UserInfo.role !== 'Пользователь'
    //   );
    // } else {
    //   return false;
    // }

  logout() {
    localStorage.removeItem('session');
    // this.cookieService.deleteAll('/', environment.domain);
    localStorage.removeItem('mobile');
    this.apiService.logout().subscribe((data)=>{
      this.cookieService.delete('Authorization', '/ ',  environment.domain, true, 'Lax');
    });
    this.router.navigate(['/login']);
  }
  setCookie(data: any): void {
    if (typeof data === 'object' && 'accessToken' in data) {
      this.cookieService.set('Authorization', data.accessToken, {
        // expires: (1 / 24 / 60 / 60) * 60 * 60 * 24 * 7,
        // expires: (1 / 24 / 60 / 60) * 120,
        expires: (1 / 24 / 60 / 60) * 30 ,
        secure: true,
        path: '/',
      });
    }
  } 

  setCookieCreatedOrder(data: any): void {
    if (typeof data === 'object' && '_id' in data) {
      this.cookieService.set('createdOrder', data._id, {
        expires: (1 / 24 / 60 / 60) * 15,
        // expires: (1 / 24 / 60 / 60) * 10,
        // expires: (1 / 24 / 60 / 60) * 10 ,
        path: '/',
      });
    }else{
      console.error("Что то пошло не так");
    }
  }
  getCookieCreatedOrder(): string {
    if(!this.cookieService.get('createdOrder')){
      return '';
    } else {
      return this.cookieService.get('createdOrder');
    }
  }

  setCookieDeletedOrder(data: any): void {
    if (typeof data === 'object' && '_id' in data) {
      this.cookieService.set('deletedOrder', data._id, {
        expires: (1 / 24 / 60 / 60) * 15,
        // expires: (1 / 24 / 60 / 60) * 10,
        // expires: (1 / 24 / 60 / 60) * 10 ,
        path: '/',
      });
    }else{
      console.error("Что то пошло не так");
    }
  }
  getCookieDeletedOrder(): string {
    if(!this.cookieService.get('deletedOrder')){
      return '';
    } else {
      return this.cookieService.get('deletedOrder');
    }
  }
  setCookieDeletedUser(data: any): void {
    if (typeof data === 'object' && '_id' in data) {
      this.cookieService.set('deletedUser', data._id, {
        expires: (1 / 24 / 60 / 60) * 5,
        // expires: (1 / 24 / 60 / 60) * 10,
        // expires: (1 / 24 / 60 / 60) * 10 ,
        path: '/',
      });
    }else{
      console.error("Что то пошло не так");
    }
  }
  getCookieDeletedUser(): string {
    if(!this.cookieService.get('deletedUser')){
      return '';
    } else {
      return this.cookieService.get('deletedUser');
    }
  }
  //TODO set in localstorage and check for Cookie will soon be rejected because it is foreign and does not have the “Partitioned“ attribute.
  //PASS IN COOKIE FROM TOKEN BECAUSE OTHERWISE CAN BREACH
  //jwtdecode on server side

  refreshCookie(): void{
    this.cookieService.delete('Authorization');
    this.getCookie().subscribe(()=>{});
  }

  getCookie(): Observable<User>{
    const cookie = this.cookieService.get('Authorization');
    if(cookie){
      const decoded = jwtDecode(cookie) as { UserInfo?: User }; // Replace 'any' with the actual type if known
      if (decoded.UserInfo) {
        return of(decoded.UserInfo);
      }else{
        throw Error('Decoded JWT token does not contain UserInfo');
      }
    }else{
      return new Observable<User>((observer) => {
        this.apiService.refresh().subscribe({
          next: (data) => {
            this.setCookie(data);
            const decoded = jwtDecode(data.accessToken) as { UserInfo?: User }; // Replace 'any' with the actual type if known
            if (decoded && decoded.UserInfo) {
              observer.next(decoded.UserInfo); // Emit the new cookie
            }else{
              throw Error('Decoded JWT token does not contain UserInfo');
            }
            observer.complete();
          },
          error: (error) => {
            observer.error(error); // Handle error
          }
        });
      });
    }
  }

  //  getCookie(): string {
  //   const cookie = this.cookieService.get('Authorization');
  //   if (cookie) {
  //     return cookie;
  //   }

  //   if (!this.refreshing) {
  //     this.refreshing = true;
  //     this.apiService.refresh().subscribe((data) => {
  //       this.setCookie(data);
  //       this.refreshing = false;
  //       console.log(this.pendingRequests)
  //       // Resolve all pending requests
  //       this.pendingRequests.forEach((resolve) => resolve());
  //       this.pendingRequests = []; // Clear the pending requests
  //     });
  //   }

  //   return ''; // Return an empty string while refreshing
  // }

  // private waitForCookie(): string {
  //   const cookie = this.getCookie();
  //   if (cookie) {
  //     return cookie; // Return the cookie if it exists
  //   }

  //   // If the cookie is refreshing, we need to wait
  //   return ''; // Return an empty string while waiting for the cookie
  // }

  // getPhone(): string {
  //   const cookie = this.waitForCookie();
  //   if (cookie) {
  //     //@ts-ignore
  //     return jwtDecode(cookie).UserInfo.phone;
  //   } else {
  //     // If the cookie is refreshing, register a pending request
  //     this.pendingRequests.push(() => {
  //       const newCookie = this.getCookie();
  //       if (newCookie) {
  //     //@ts-ignore
  //         return jwtDecode(newCookie).UserInfo.phone;
  //       }
  //     });
      
  //     return ''; // Return an empty string while waiting
  //   }
  // }

  // getName(): string {
  //   const cookie = this.waitForCookie();
  //   if (cookie) {
  //     //@ts-ignore
  //     return jwtDecode(cookie).UserInfo.name;
  //   } else {
  //     this.pendingRequests.push(() => {
  //       const newCookie = this.getCookie();
  //       if (newCookie) {
  //     //@ts-ignore
  //         return jwtDecode(newCookie).UserInfo.name;
  //       }
  //     });
  //     return ''; // Return an empty string while waiting
  //   }
  // }

  // getRole(): string {
  //   const cookie = this.waitForCookie();
  //   if (cookie) {
  //     //@ts-ignore
  //     return jwtDecode(cookie).UserInfo.role;
  //   } else {
  //     this.pendingRequests.push(() => {
  //       const newCookie = this.getCookie();
  //       if (newCookie) {
  //     //@ts-ignore
  //         return jwtDecode(newCookie).UserInfo.role;
  //       }
  //     });
  //     console.log('АЛЯРМ АЛЯРМ АЛЯРМ АЛЯРМ АЛЯРМ АЛЯРМ АЛЯРМ АЛЯРМ АЛЯРМ АЛЯРМ АЛЯРМ ');
  //     return ''; // Return an empty string while waiting
  //   }
  // }

  // getPerson(): string {
  //   const cookie = this.waitForCookie();
  //   if (cookie) {
  //     //@ts-ignore
  //     return jwtDecode(cookie).UserInfo.person;
  //   } else {
  //     this.pendingRequests.push(() => {
  //       const newCookie = this.getCookie();
  //       if (newCookie) {
  //     //@ts-ignore
  //         return jwtDecode(newCookie).UserInfo.person;
  //       }
  //     });
  //     return ''; // Return an empty string while waiting
  //   }
  // }

  // getCompany(): string {
  //   const cookie = this.waitForCookie();
  //   if (cookie) {
  //     //@ts-ignore
  //     return jwtDecode(cookie).UserInfo.company;
  //   } else {
  //     this.pendingRequests.push(() => {
  //       const newCookie = this.getCookie();
  //       if (newCookie) {
  //     //@ts-ignore
  //         return jwtDecode(newCookie).UserInfo.company;
  //       }
  //     });
  //     return ''; // Return an empty string while waiting
  //   }
  // }

  // isVerified(): boolean {
  //   const cookie = this.waitForCookie();
  //   if (cookie) {
  //     //@ts-ignore
  //     return jwtDecode(cookie).UserInfo.verified;
  //   } else {
  //     this.pendingRequests.push(() => {
  //       const newCookie = this.getCookie();
  //       if (newCookie) {
  //     //@ts-ignore
  //         return jwtDecode(newCookie).UserInfo.verified;
  //       }
  //     });
  //     return false; // Return false while waiting
  //   }
  // }
//   getCookie(): string {
//     localStorage.removeItem('getCookie');
//     if(!this.cookieService.get('Authorization')){
//       localStorage.setItem('getCookie', '1');
//       this.apiService.refresh().subscribe((data)=>{
//         // location.reload();
//         this.setCookie(data);
//         return this.cookieService.get('Authorization');
//       });
//       return 'refreshing';
//     }else
//     return this.cookieService.get('Authorization'); 
//   }
//   getPhone(): string {
//     if(this.getCookie() === 'refreshing'){
//       return this.getPhone();
//     }else{
//       // @ts-ignore If not authorized there is no cookie if authorized there should be cookie
//       return jwtDecode(this.getCookie()).UserInfo.phone;
//     }
//   }
//   getName(): string {
//     if(this.getCookie() === 'refreshing'){
//       return this.getName();
//     }else{
//       // @ts-ignore If not authorized there is no cookie if authorized there should be cookie
//       return jwtDecode(this.getCookie()).UserInfo.name;
//     }
//   }
//   getRole(): string {
//     if(this.getCookie() === 'refreshing'){
//       return this.getRole();
//     }else{
//       // @ts-ignore If not authorized there is no cookie if authorized there should be cookie
//       return jwtDecode(this.getCookie()).UserInfo.role;
//     }
//   }
//   getPerson(): string {
//     if(this.getCookie() === 'refreshing'){
//       return this.getPerson();
//     }else{
//       // @ts-ignore If not authorized there is no cookie if authorized there should be cookie
//       return jwtDecode(this.getCookie()).UserInfo.person;
//      }

//   }
//   getCompany(): string {
//     if(this.getCookie() === 'refreshing'){
//       return this.getCompany();
//     }else{
//       // @ts-ignore If not authorized there is no cookie if authorized there should be cookie
//       return jwtDecode(this.getCookie()).UserInfo.company;
//     }
// }
//   isVerified(): boolean {
//     if(this.getCookie() === 'refreshing'){
//       return this.isVerified();
//     }else{
//       // @ts-ignore If not authorized there is no cookie if authorized there should be cookie
//       return jwtDecode(this.getCookie()).UserInfo.verified;
//     }

//   }
  // userRole(): 'Admin' | 'User' | 'Manager' {
  //   return 'User';
  // }
}
