<div class="flexbox-container">
  @if(!loadingOpt && !loadingPrice){
  <div class="options-change">
    <p class="options-change-text">Изменить настройки 🔒</p>
    <ul>
      <li>
        <button class="button-change" (click)="renderOptions('thickness')">
          Толщина
        </button>
      </li>
      <li>
        <button class="button-change" (click)="renderOptions('collectPVC')">
          Коллекции ПВХ
        </button>
      </li>
      <li>
        <button class="button-change" (click)="renderOptions('collectLUX')">
          Коллекции LUX
        </button>
      </li>
      <li>
        <button class="button-change" (click)="renderOptions('milling')">
          Фрезеровка
        </button>
      </li>
      <li>
        <button class="button-change" (click)="renderOptions('decorPVC')">
          Декоры ПВХ
        </button>
      </li>
      <li>
        <button class="button-change" (click)="renderOptions('decorLUX')">
          Декоры LUX
        </button>
      </li>
      <!--<li>
        <button class="button-change" (click)="renderOptions('kromka')">
          Кромка
        </button>
      </li>-->
      <li>
        <button class="button-change" (click)="renderOptions('ispol')">
          Вид фасада
        </button>
      </li>
      <li>
        <button class="button-change" (click)="renderOptions('star')">
          Эффекты старения
        </button>
      </li>
      <li>
        <button class="button-change" (click)="renderOptions('polish')">
          Лак
        </button>
      </li>
      <li>
        <button class="button-change" (click)="renderOptions('lamellaDecor')">
          Декор реек
        </button>
      </li>
      <li>
        <button class="button-change" (click)="renderPrice('pricePVС')">
          Цены ПВХ
        </button>
      </li>
      <li>
        <button class="button-change" (click)="renderPrice('priceLUX')">
          Цены LUX
        </button>
      </li>
    </ul>
  </div>
  <div class="options-message">
    @if(checkRender){
    <!--Если прайс-->
    @if(checkPrice){
    <!--Если LUX-->
    @if(checkLUX){
    <h3 class="render-reminder">Цены LUX</h3>
    <div>
      <span class="render-reminder">Без кромки</span>
      <span class="render-reminder">Кромка в цвет</span>
      <span class="render-reminder">Дизайн кромка</span>
      <span class="render-reminder">С трёх сторон</span>
    </div>
    @for (thickness of options.thickness.entries; track $index) {
      <h4 class="render-reminder"> {{ thickness }} </h4>
      @for (item of options.collectLUX.entries; track $index) {
        @if (options.collectLUX.thicknessDep.entries[item].includes(thickness)) {
          <div>
            <span class="price-lux">{{item}} </span>
            <input type="number" class="price-lux"
            [(ngModel)]="arrayAsAnyPrice[thickness][item]['Без кромки']"
            [ngClass]="{
              greyedout: arrayAsAnyPrice[thickness][item]['Без кромки'] <= 0
              }">
            <input type="number" class="price-lux"
            [(ngModel)]="arrayAsAnyPrice[thickness][item]['Кромка в цвет']"
            [ngClass]="{
              greyedout: arrayAsAnyPrice[thickness][item]['Кромка в цвет'] <= 0
              }">
            <input type="number" class="price-lux"
            [(ngModel)]="arrayAsAnyPrice[thickness][item]['Дизайн кромка']"
            [ngClass]="{
              greyedout: arrayAsAnyPrice[thickness][item]['Дизайн кромка'] <= 0
              }">
            <input type="number" class="price-lux"
            [(ngModel)]="arrayAsAnyPrice[thickness][item]['С трёх сторон']"
            [ngClass]="{
              greyedout: arrayAsAnyPrice[thickness][item]['С трёх сторон'] <= 0
              }">
          </div>
        }
      }
    }
   } @else{
    <h3 class="render-reminder">Цены ПВХ</h3>
    <div>
      <span class="render-reminder">1</span>
      <span class="render-reminder">2</span>
      <span class="render-reminder">3</span>
      <span class="render-reminder">4</span>
      <span class="render-reminder">5</span>
    </div>
    @for (thickness of options.thickness.entries; track $index) {
      <h4 class="render-reminder"> {{ thickness }} </h4>
      @for (item of options.collectPVC.entries; track $index) {
        @if (options.collectPVC.thicknessDep.entries[item].includes(thickness)) {
          <div>
            <span class="price-lux">{{item}} </span>

            <input type="number" class="price-lux"
            [(ngModel)]="arrayAsAnyPrice[thickness][item]['1']"
            [ngClass]="{
              greyedout: arrayAsAnyPrice[thickness][item]['1'] <= 0
              }">

            <input type="number" class="price-lux"
            [(ngModel)]="arrayAsAnyPrice[thickness][item]['2']"
            [ngClass]="{
              greyedout: arrayAsAnyPrice[thickness][item]['2'] <= 0
              }">

            <input type="number" class="price-lux"
            [(ngModel)]="arrayAsAnyPrice[thickness][item]['3']"
            [ngClass]="{
              greyedout: arrayAsAnyPrice[thickness][item]['3'] <= 0
              }">

            <input type="number" class="price-lux"
            [(ngModel)]="arrayAsAnyPrice[thickness][item]['4']"
            [ngClass]="{
              greyedout: arrayAsAnyPrice[thickness][item]['4'] <= 0
              }">

            <input type="number" class="price-lux"
            [(ngModel)]="arrayAsAnyPrice[thickness][item]['5']"
            [ngClass]="{
              greyedout: arrayAsAnyPrice[thickness][item]['5'] <= 0
              }">

          </div>
        }
      }
    }
  }
    <button class="make-changes" (click)="pushPrices()">Изменить</button>
    } @else{
    <h3 class="render-reminder">{{ renderToRus[curRender] }}</h3>
    @for (key of helper; track $index) {
    <p class="dep-helper">Зависимость <span style="font-weight: bold;">{{ depToRus[key] }}</span> для
      {{ renderToRusDecline[curRender] }}</p>
    }
    @if(curRender == 'milling'){
      <div>
        <span class="filter-text">Фильтр по коллекции:</span>
        <select name="millingFilter" id="millingFilter"
        [(ngModel)]="millingFilter">
          @for (item of options.collectPVC.entries; track $index) {
            <option value="{{item}}">{{item}}</option>
          }
          <option value="">Без фильтра</option>
        </select>
      </div>
    }
    @if(curRender == 'decorPVC'){
      <div>
        <span class="filter-text">Фильтр по группе:</span>
        <select name="decorPVCFilter" id="decorPVCFilter"
        [(ngModel)]="decorPVCFilter">
          @for (item of ['1','2','3','4','5']; track $index) {
            <option value="{{item}}">{{item}}</option>
          }
          <option value="">Без фильтра</option>
        </select>
      </div>
    }
    @if(curRender == 'decorLUX'){
      <div>
        <span class="filter-text">Фильтр по коллекции:</span>
        <select name="decorLUXFilter" id="decorLUXFilter"
        [(ngModel)]="decorLUXFilter">
          @for (item of options.collectLUX.entries; track $index) {
            <option value="{{item}}">{{item}}</option>
          }
          <option value="">Без фильтра</option>
        </select>
      </div>
    }
    <ul>
      @for (opt of curOptions.entries; track $index) {
        @if(checkFilter(opt, 'milling') && checkFilter(opt, 'decorPVC') && checkFilter(opt, 'decorLUX'))
          {
          <li class="options-position-main">
            {{ opt
            }}<button
              mat-mini-fab
              class="delete-button"
              (click)="deletePosition(opt)"
            >
              <img src="\assets\cross.png" width="25" height="25" />
            </button>
          </li>
          @for (key of helper; track $index) {
            <div class="change-state">
              @if(key !== 'millingDep'){
                &#8226;
              }
               @if (!key.includes('Dep')) {
                Такого быть не должно
              }@else if (key === 'styleDep') {
                <select name="style" id="style"
                [(ngModel)]="arrayAsAny[key][opt]"
                (change)="universalChange(key,opt)"
                >
                  <option value="Матовый" selected>Матовый</option>
                  <option value="Глянцевый" selected>Глянцевый</option>
                  <option value="Древесный" selected>Древесный</option>
                </select>
              }
              @else if (key === 'frezaDep') {
                @for (freza of ['1','2','3','5']; track $index) {
                  <button
                  class="options-check"
                  [ngClass]="{
                    true: arrayAsAny[key][opt].includes(freza),
                    false: !arrayAsAny[key][opt].includes(freza),
                    }"
                    (click)="changeState(opt, key, freza)"
                    >
                      {{ freza }}
                  </button>
                }
              } @else if (key === 'groupDep'){
                <select name="group" id="group"
                [(ngModel)]="arrayAsAny[key][opt]"
                (change)="universalChange(key,opt)"
                >
                  <option value="1" selected>1</option>
                  <option value="2" selected>2</option>
                  <option value="3" selected>3</option>
                  <option value="4" selected>4</option>
                  <option value="5" selected>5</option>
                  <option value="6" selected>6</option>
                </select>
              } @else if (key === 'catalogDep'){
                <select name="catalog" id="catalog"
                [(ngModel)]="arrayAsAny[key][opt]"
                (change)="universalChange(key, opt)" >
                  <option value="mono" selected>mono</option>
                  <option value="wood" selected>wood</option>
                </select>
              } @else if ( key === 'integraDep' ){
                <select name="integra" id="integra"
                [(ngModel)]="arrayAsAny[key][opt]"
                (change)="universalChange(key, opt)" >
                  <option value="Integra" selected>Интегра возможна</option>
                  <option value="" selected>Интегра невозможна</option>
                </select>
              } @else if ( key === 'collectDep'){
                <select name="collect" id="collect"
                [(ngModel)]="arrayAsAny[key][opt]"
                (change)="universalChange(key, opt)">
                  @for (item of options[mapDepToHelp[key]].entries; track $index) {
                    <option value="{{item}}">{{item}}</option>
                  }
                </select>
              } @else if ( key === 'linkDep'){
                <input type="text" class="link-input" [(ngModel)]="arrayAsAny[key][opt]"
                  (change)="universalChange(key, opt)">
                <!-- <select name="collect" id="collect"
                [(ngModel)]="arrayAsAny[key][opt]"
                (change)="universalChange(key, opt)">
                  @for (item of options[mapDepToHelp[key]].entries; track $index) {
                    <option value="{{item}}">{{item}}</option>
                  }
                </select> -->
              }
               @else if ( key === 'priceDep'){
                <input type="number" [(ngModel)]="arrayAsAny[key][opt]" (change)="universalChange(key, opt)">
              } @else if (key === 'priceMultDep') {
                <select name="priceMultDep" id="priceMultDep"
                [(ngModel)]="arrayAsAny[key][opt]"
                (change)="universalChange(key, opt)">
                  <option value="true" selected>*</option>
                  <option value="false" selected>+</option>
                </select>
              }@else if (key === 'minHeightDep') {
                <input type="number" [(ngModel)]="arrayAsAny[key][opt]"
                (change)="universalChange(key, opt)">
              }
              @else if (key === 'minWidthDep') {
                <input type="number" [(ngModel)]="arrayAsAny[key][opt]"
                (change)="universalChange(key, opt)">
              }@else if (key === 'maxHeightDep') {
                <input type="number" [(ngModel)]="arrayAsAny[key][opt]"
                (change)="universalChange(key, opt)">
              }
              @else if (key === 'maxWidthDep') {
                <input type="number" [(ngModel)]="arrayAsAny[key][opt]"
                (change)="universalChange(key, opt)">
              }
              @else if (key === 'millingDep') {
              } @else{
                @for (dep of options[mapDepToHelp[key]].entries; track $index) {
                  <button
                    class="options-check"
                    [ngClass]="{
                    true: arrayAsAny[key][opt].includes(dep),
                    false: !arrayAsAny[key][opt].includes(dep),
                    }"
                    (click)="changeState(opt, key, dep)">
                      {{ dep }}
                  </button>
                }
              }
            </div>
            }
          }
        }
    </ul>
    <div class="add-position">
      <label for="addid">Добавить позицию: </label>
      <input type="text" id="addid" [(ngModel)]="addName" />
      <button mat-mini-fab class="add-button" (click)="addPosition(addName)">
        <img src="\assets\add.png" width="25" height="25" />
      </button>
      <button class="make-changes" (click)="pushChanges()">Изменить</button>
    </div>
    }
  }
  </div>
  <p class="message">{{ message }}</p>
  <p class="error-message">{{ errorMessage }}</p>
  } @else {
  <h2>{{ errorMessage }}</h2>
  }
</div>
