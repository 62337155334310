<head>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
</head>
<div class="white-page">
   <!-- @if(role == 'Админ'){
    <button (click)="getCookie()">СЮДА СЮДА СЮДА</button>
    <button (click)="removeAllCookies()">НЕСЮДА НЕСЮДА НЕСЮДА</button>
  } -->
  @if(errorMessage){<p class="error-message">{{ errorMessage }}</p>}
  @if(loading){
    <h3>Загрузка</h3>
  } @else{
    @if(createdID){
      <p class="green created-order">Заказ успешно <a href="/order/{{createdID}}">создан</a> </p>
    }
    @if(deletedID){
      <p class="red created-order">Заказ {{deletedID}} успешно удалён </p>
    }
    @if(errorMessage=== 'Список заказов пуст.'){

      <div class="border-radius">
        <table>
          <thead>
            <tr>
              <th style="border-bottom: none;">№</th>
              <th style="border-bottom: none;">Создан</th>
              <th style="border-bottom: none;">Дата оплаты</th>
                <!-- <th style="border-bottom: none;">Дата поступ-<br>ления на<br> склад</th> -->
                <th style="border-bottom: none;">План-дата поступления на склад</th>
                <th style="border-bottom: none;">Факт-дата поступления на склад</th>
                <th style="border-bottom: none;">Номер</th>
                <th style="border-bottom: none;">Статус</th>
                <th style="border-bottom: none;">Розничная сумма</th>
              @if(personCheck){
                <!-- <th style="border-bottom: none;">Сумма РРЦ с %</th> -->
                <th style="border-bottom: none;">Оптовая сумма</th>
              }
              <th style="border-bottom: none;">S<sub>общая</sub></th>
              @if(roleCheck){
                <!-- <th style="border-bottom: none;">Лицо</th> -->
                <th style="border-bottom: none;">ФИО</th>
              }
              @if(personCheck){ 
                <th style="border-bottom: none;">Клиент</th>
              }
              <th style="border-bottom: none;">Ответственный</th>
            </tr>
          </thead>
        </table>
      </div>
    }
    @else {
      @if(roleCheck){
        <input class="search" type="text" [(ngModel)]="searchWord" (input)="search()" placeholder="Поиск" onclick="this.select()"> 
      }
      <div class="border-radius">
        <table>
          <thead>
            <tr>
              <th>№</th>
              <th (click)="sort('created')">
                <span style="text-wrap: nowrap;">
                  Создан <i class="fa" [ngClass]="{'fa-sort': sortField !== 'created', 'fa-sort-up': sortField === 'created' && sortOrder === 'asc', 'fa-sort-down': sortField === 'created' && sortOrder === 'desc'}"></i>
                </span>
              </th>
              <th (click)="sort('paymentStatus')">
                Дата 
                <span style="text-wrap: nowrap;">
                  оплаты <i class="fa" [ngClass]="{'fa-sort': sortField !== 'paymentStatus', 'fa-sort-up': sortField === 'paymentStatus' && sortOrder === 'asc', 'fa-sort-down': sortField === 'paymentStatus' && sortOrder === 'desc'}"></i>
                </span>
              </th>
              <th (click)="sort('dateWhenWarehouse')">
                План-дата поступления на 
                <span style="text-wrap: nowrap;">
                  склад<i class="fa" [ngClass]="{'fa-sort': sortField !== 'dateWhenWarehouse', 'fa-sort-up': sortField === 'dateWhenWarehouse' && sortOrder === 'asc', 'fa-sort-down': sortField === 'dateWhenWarehouse' && sortOrder === 'desc'}"></i>
                </span>
              </th>
              <th (click)="sort('dateWhenShipping')">
                Факт-дата поступления на 
                <span style="text-wrap: nowrap;">
                  склад<i class="fa" [ngClass]="{'fa-sort': sortField !== 'dateWhenShipping', 'fa-sort-up': sortField === 'dateWhenShipping' && sortOrder === 'asc', 'fa-sort-down': sortField === 'dateWhenShipping' && sortOrder === 'desc'}"></i>
                </span>
              </th>  
              <th (click)="sort('customID')">
                <span style="text-wrap: nowrap;">
                  Номер <i class="fa" [ngClass]="{'fa-sort': sortField !== 'customID', 'fa-sort-up': sortField === 'customID' && sortOrder === 'asc', 'fa-sort-down': sortField === 'customID' && sortOrder === 'desc'}"></i>
                </span>
              </th>
              <th (click)="sort('status')">
                Статус <i class="fa" [ngClass]="{'fa-sort': sortField !== 'status', 'fa-sort-up': sortField === 'status' && sortOrder === 'asc', 'fa-sort-down': sortField === 'status' && sortOrder === 'desc'}"></i></th>
                <th (click)="sort('totalPrice')">
                  Розничная 
                  <span style="text-wrap: nowrap;">
                    сумма <i class="fa" [ngClass]="{'fa-sort': sortField !== 'totalPrice', 'fa-sort-up': sortField === 'totalPrice' && sortOrder === 'asc', 'fa-sort-down': sortField === 'totalPrice' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
              @if(personCheck){
                <!-- <th (click)="sort('totalPriceMarkup')">
                  Сумма РРЦ 
                  <span style="text-wrap: nowrap;">
                    с %<i class="fa" [ngClass]="{'fa-sort': sortField !== 'totalPriceMarkup', 'fa-sort-up': sortField === 'totalPriceMarkup' && sortOrder === 'asc', 'fa-sort-down': sortField === 'totalPriceMarkup' && sortOrder === 'desc'}"></i>
                  </span>
                </th> -->
                <th (click)="sort('totalPriceDiscount')">
                  Оптовая 
                  <span style="text-wrap: nowrap;">
                    сумма<i class="fa" [ngClass]="{'fa-sort': sortField !== 'totalPriceDiscount', 'fa-sort-up': sortField === 'totalPriceDiscount' && sortOrder === 'asc', 'fa-sort-down': sortField === 'totalPriceDiscount' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
              }
              <th (click)="sort('totalArea')">
                <span style="text-wrap: nowrap;">
                  S<sub>общ</sub> <i class="fa" [ngClass]="{'fa-sort': sortField !== 'totalArea', 'fa-sort-up': sortField === 'totalArea' && sortOrder === 'asc', 'fa-sort-down': sortField === 'totalArea' && sortOrder === 'desc'}"></i>
                </span>
              </th>
              @if(roleCheck){
                <!-- <th (click)="sort('person')">
                  <span style="text-wrap: nowrap;">
                    Лицо <i class="fa" [ngClass]="{'fa-sort': sortField !== 'person', 'fa-sort-up': sortField === 'person' && sortOrder === 'asc', 'fa-sort-down': sortField === 'person' && sortOrder === 'desc'}"></i>
                  </span>
                </th> -->
                <th (click)="sort('name')">
                  <span style="text-wrap: nowrap;">
                    ФИО <i class="fa" [ngClass]="{'fa-sort': sortField !== 'name', 'fa-sort-up': sortField === 'name' && sortOrder === 'asc', 'fa-sort-down': sortField === 'name' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
              }
              @if(personCheck){ 
                <th style='border-bottom: none;'(click)="sort('commentsForClient')">
                  <span style="text-wrap: nowrap;">
                    Клиент<i class="fa" [ngClass]="{'fa-sort': sortField !== 'commentsForClient', 'fa-sort-up': sortField === 'commentsForClient' && sortOrder === 'asc', 'fa-sort-down': sortField === 'commentsForClient' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
              }
              <!-- <th (click)="sort('totalArea')">
                <span style="text-wrap: nowrap;">
                  S<sub>общая</sub> <i class="fa" [ngClass]="{'fa-sort': sortField !== 'totalArea', 'fa-sort-up': sortField === 'totalArea' && sortOrder === 'asc', 'fa-sort-down': sortField === 'totalArea' && sortOrder === 'desc'}"></i>
                </span>
              </th> -->
              <th (click)="sort('responsible')">
                <span style="text-wrap: nowrap;">
                  Ответственный <i class="fa" [ngClass]="{'fa-sort': sortField !== 'responsible', 'fa-sort-up': sortField === 'responsible' && sortOrder === 'asc', 'fa-sort-down': sortField === 'responsible' && sortOrder === 'desc'}"></i>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            @for (order of orderListSorted; track $index) {
            <tr>
              <td>{{$index+1}}</td>
              <td>{{ order.created | date }}</td>
              <td>
                @if(order.paymentStatus === 'Ожидает оплаты'){
                  ----
                } @else {
                  {{ order.paymentStatus }}
                }
              </td>
              <td>{{ order.dateWhenWarehouse }}
                  @if(!order.dateWhenWarehouse)
                    { ---- }
              </td>
              <td>
              @if(!order.dateWhenShipping) {
                ----
              } @else{
                {{ order.dateWhenShipping}}
              }
              </td>
              <td>
                <a href="/order/{{ order._id }}" class="number-text">
                  @if(!order.customID)
                    { Номер }
                  @else {
                    {{ order.customID }}
                  }
                </a>
                <p class="type">
                  @if(order.kind){
                    {{ TYPE_TO_RUS[order.kind] }}
                  }
                </p>
              </td>
              <td [ngClass]="{'red': order.status === 'Ожидает оплаты','yellow': order.status === 'Изготавливается', 'green': order.status ==='На складе', 'blue': order.status ==='Сохранён'}"> {{ order.status }} </td>
              <td> <span style="text-wrap: nowrap;">{{ order.totalPrice | number }}</span> </td>
              @if(personCheck){
                <!-- <td> <span style="text-wrap: nowrap;">{{ order.totalPriceMarkup | number }}</span> </td> -->
                <td>
                  <!-- @if(!order.totalPriceMarkup){
                    ----
                  }
                  <span style="text-wrap: nowrap;">{{ order.totalPriceMarkup | number }}</span> -->
                  <ng-container *ngIf="order.pointsUsed; else noPointsUsed">
                    <p style="text-decoration: underline;" 
                       matTooltip="Использовано {{ order.pointsUsed }} бонусов" 
                       matTooltipPosition="above">
                       {{order.totalPriceMarkup | number}}
                    </p>
                  </ng-container>
                  <ng-template #noPointsUsed>
                      {{order.totalPriceMarkup | number }}
                  </ng-template>
                </td>
              }
              <td><span style="text-wrap: nowrap;">{{order.totalArea}} м<sup>2</sup></span></td>
              @if(roleCheck){
                <!-- <td>{{ order.person }}</td> -->
                <td [ngClass]="{'green-bg': order.createdResponsible === name}">
                  {{ order.name }}<ng-container *ngIf="order.createdResponsible" >
                    <span  
                       matTooltip="{{ order.createdResponsible }}" 
                       matTooltipPosition="above">*
                    </span>
                  </ng-container>
                </td>
              }
              @if(personCheck){
               <td>
                @if(!order.commentsForClient){
                ----
               } @else {
                {{ order.commentsForClient }}
               }
              </td>
              }
              <!-- <td>{{ order.totalArea | number }}м<sup>2</sup></td> -->
              <!-- <td>
                @if(!order.responsible){ ---- }
                @else {
                  <p style="text-decoration: underline; cursor: help;" title='{{order.responsiblePhone}}'> {{ order.responsible }} </p>
                }
              </td> -->
              <td>
                <ng-container *ngIf="order.responsible; else noResponsible">
                    <p style="text-decoration: underline;" 
                       matTooltip="{{ order.responsiblePhone }}" 
                       matTooltipPosition="above">
                        {{ order.responsible }}
                    </p>
                </ng-container>
                <ng-template #noResponsible>
                    ----
                </ng-template>
            </td>
            
            </tr>
            }
          </tbody>
        </table>
      </div>
    }
  }
</div>
