import { Component } from '@angular/core';
import { ApiService } from '../services/api.service';
import { User } from '../interfaces/user';
import { pointsHistory } from '../interfaces/user';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-points',
  standalone: true,
  imports: [],
  templateUrl: './points.component.html',
  styleUrl: './points.component.scss'
})
export class PointsComponent {
  constructor(private apiService: ApiService, private authService: AuthService) {
      authService.getCookie().subscribe((data)=>{
        if(data._id){
          apiService.getUserPoints(data._id).subscribe((data: any)=>{
            if(data && data.pointsHistory){
              this.pointsHistory = data.pointsHistory;
            }
            this.loading = false;
          })
        }else{
          this.errorMessage = 'Пользователь не найден';
          this.loading = false;
        }
      })
  }
  //@ts-ignore
  pointsHistory: pointsHistory[];
  loading: boolean = true;
  errorMessage: string = '';
}
