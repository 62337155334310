import { Component, inject } from '@angular/core';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { Order } from '../interfaces/order';
import { DatePipe } from '../pipes/date.pipe';
import { NumberPipe } from '../pipes/number.pipe';
import { DateBitrixPipe } from '../pipes/date-bitrix.pipe';
import { NgClass, NgIf } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';
@Component({
  selector: 'app-orders-list',
  standalone: true,
  imports: [DatePipe, NumberPipe, DateBitrixPipe, NgClass,MatTooltipModule, NgIf, FormsModule],
  templateUrl: './orders-list.component.html',
  styleUrl: './orders-list.component.scss',
})
export class OrdersListComponent {
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private cookieService: CookieService
  ) {
    authService.getCookie().subscribe((user)=>{
      let role = user.role;
      this.role = role;
      this.name = user.name;
      let person = user.person;
      let phoneChildren;
      let parentID;
      if(user.person == 'Сотрудник производителя мебели' && user.parentID){
        phoneChildren = user.phone;
        parentID = user.parentID;
        this.parentID = user.parentID;
      }
      if (person !== 'Розничный покупатель') {
        this.personCheck = true;
      }
      if (role !== 'Пользователь') {
        this.roleCheck = true;
        this.personCheck = true;
      }
      //@ts-ignore
      apiService.getOrderList(parentID).subscribe((data: Order[]) => {
        if (data && 'message' in data) {
          this.errorMessage = JSON.stringify(data.message);
          return;
        }
        if (data) {
          this.loading = false;
          this.errorMessage = '';
          // this.orderList = data;
          this.orderList = data.sort((a,b) => {
            // if (a.created && b.created) {
            //   console.log(a.created + " " + b.created);
            //   return  b.created.localeCompare(a.created);
            // } else {
            //     return 0; // Keep the order unchanged
            // }
            if (a._id && b._id) {
              // console.log(a._id + " " + b._id);
              return  b._id.localeCompare(a._id);
            } else {
                return 0; // Keep the order unchanged
            }
        });
        this.orderListSorted = this.orderList;
        } else {
          this.errorMessage = 'Список заказов пуст.';
          this.loading = false;
        }
      });
    });
    this.createdID = authService.getCookieCreatedOrder();
    this.deletedID = authService.getCookieDeletedOrder();
  }

  TYPE_TO_RUS: { [key: string]: string } = {
    '': '',
    'facade': 'Фасады',
    'lamella': 'Рейки',
  };

  orderList!: Order[];
  orderListSorted!: Order[];

  loading: boolean = true;
  roleCheck: boolean = false;
  personCheck: boolean = false;
  discountCheck: boolean = false;
          
  role: string = '';
  name: string = '';
  parentID: string = '';

  createdID: string = '';
  deletedID: string = '';
  errorMessage: string = '';

  private statusOrder: { [key: string]: number } = {
    'Сохранён': 1,
    'В обработке у менеджера': 2,
    'Ожидает оплаты': 2,
    'Изготавливается': 3,
    'На складе': 4,
    'Отгружено': 5,
    // Add other statuses as needed
  };

  // Sorting properties
  sortField: string = 'created'; // Default sort field
  sortOrder: 'asc' | 'desc' = 'asc'; // Default sort order
  
  searchWord: string = '';
  
  search(){
    this.orderListSorted = this.orderList.filter(item => {
      return Object.values(item).some(value => 
        String(value).toLowerCase().includes(this.searchWord.toLowerCase())
      )
    });
  }

  sort(field: string) {
    if (this.sortField === field) {
      // Toggle sort order if the same field is clicked
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      // Set new field and default to ascending order
      this.sortField = field;
      this.sortOrder = 'asc';
    }
    this.sortOrders();
  }

  private parseDate(dateString: string): Date {
    const parts = dateString.split('/');
    // Check if the dateString is in the expected format
    if (parts.length === 3) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // Months are 0-based in JavaScript
      const year = parseInt(parts[2], 10) + 2000; // Assuming YY is in the 21st century
      return new Date(year, month, day);
    }
    return new Date(NaN); // Return an invalid date if the format is incorrect
  }
  

  sortOrders() {
    this.orderList.sort((a, b) => {
      const aValue = a[this.sortField as keyof Order];
      const bValue = b[this.sortField as keyof Order];
  
      // Handle undefined values for the sort field
      if (aValue === undefined && bValue === undefined) {
        return 0; // Both are undefined, consider equal
      }
      if (aValue === undefined) {
        return this.sortOrder === 'asc' ? 1 : -1; // Treat undefined as greater
      }
      if (bValue === undefined) {
        return this.sortOrder === 'asc' ? -1 : 1; // Treat undefined as lesser
      }
  
      // Compare the primary sort values based on their types
      let comparison = 0;
      if (this.sortField === 'status') {
        // Use the custom order for status
        const aStatusOrder = this.statusOrder[aValue as string] || Number.MAX_VALUE; // Default to a high value if not found
        const bStatusOrder = this.statusOrder[bValue as string] || Number.MAX_VALUE; // Default to a high value if not found
        comparison = aStatusOrder - bStatusOrder; // Compare based on custom order
      }
      else if (this.sortField === 'created') {
        // If sorting by created, parse the date strings into Date objects
        const dateA = this.parseDate(aValue as string); // Convert to Date
        const dateB = this.parseDate(bValue as string); // Convert to Date
        comparison = dateA.getTime() - dateB.getTime();
      } else {
        // For other fields, use the existing comparison logic
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          comparison = aValue.localeCompare(bValue);
        } else if (typeof aValue === 'number' && typeof bValue === 'number') {
          comparison = aValue - bValue;
        }
      }
  
      // Return the comparison result based on sort order
      return this.sortOrder === 'asc' ? comparison : -comparison;
    });
  }
  
  // sortOrders() {
  //   console.log(this.sortField);
  //   this.orderList.sort((a, b) => {
  //     const aValue = a[this.sortField as keyof Order];
  //     const bValue = b[this.sortField as keyof Order];
      
  //     console.log(`aValue= ${aValue}, bValue= ${bValue}`)
  //     if (aValue === undefined && bValue === undefined) {
  //       return 0; // Both are undefined, consider equal
  //     }
  //     if (aValue === undefined) {
  //       return this.sortOrder === 'asc' ? 1 : -1; // Treat undefined as greater
  //     }
  //     if (bValue === undefined) {
  //       return this.sortOrder === 'asc' ? -1 : 1; // Treat undefined as lesser
  //     }
  //     if (aValue < bValue) {
  //       return this.sortOrder === 'asc' ? -1 : 1;
  //     }
  //     if (aValue > bValue) {
  //       return this.sortOrder === 'asc' ? 1 : -1;
  //     }
    
  //     if(this.sortField != 'created'){
  //       // If values are equal, sort by created date
  //       const aCreated = a.created;
  //       const bCreated = b.created;
  //       // Handle undefined values for created date
  //       if (aCreated === undefined && bCreated === undefined) {
  //         return 0; // Both are undefined, consider equal
  //       }
  //       if (aCreated === undefined) {
  //         return 1; // Treat undefined as greater
  //       }
  //       if (bCreated === undefined) {
  //         return -1; // Treat undefined as lesser
  //       }
  //         // If values are equal, sort by created date
  //       return +aCreated - +bCreated;
  //       }
  //     });
  //     return 0;
  // }

  getCookie(){
    console.log(this.cookieService.get('Authorization'));
  }
  removeAllCookies(){
    // this.cookieService.delete('Authorization', '/ ',  environment.domain);
    console.log(this.cookieService.check('Authorization'));
    console.log(this.cookieService.get('Authorization'));
    this.cookieService.deleteAll('/');
    console.log(this.cookieService.check('Authorization'));
    console.log(this.cookieService.get('Authorization'));
    console.log(environment.domain);

  }
}
