<head>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
</head>
<div class="white-page">
  @if (loading || errorMessage) {
    <h2>Загрузка данных</h2>
    <h2>{{errorMessage}}</h2>
  } @else{
  <input class="search" type="text" [(ngModel)]="searchWord" (input)="search()" placeholder="Поиск" onclick="this.select()"> 
  <div class="border-radius">
    <table>
      <thead>
        <tr>
          <th>№</th>
          <th (click)="sort('bitrixID')">
            <span style="text-wrap: nowrap;">
              ID <i class="fa" [ngClass]="{'fa-sort': sortField !== 'bitrixID', 'fa-sort-up': sortField === 'bitrixID' && sortOrder === 'asc', 'fa-sort-down': sortField === 'bitrixID' && sortOrder === 'desc'}"></i>
            </span>
          </th>
          <th>Телефон</th>
          <th (click)="sort('name')">
            <span style="text-wrap: nowrap;">
              ФИО <i class="fa" [ngClass]="{'fa-sort': sortField !== 'name', 'fa-sort-up': sortField === 'name' && sortOrder === 'asc', 'fa-sort-down': sortField === 'name' && sortOrder === 'desc'}"></i>
            </span>
          </th>
          <th (click)="sort('amountMonth')">
            <span style="text-wrap: nowrap;">
              Заказы за месяц <i class="fa" [ngClass]="{'fa-sort': sortField !== 'amountMonth', 'fa-sort-up': sortField === 'amountMonth' && sortOrder === 'asc', 'fa-sort-down': sortField === 'amountMonth' && sortOrder === 'desc'}"></i>
            </span>
          </th>
          <th (click)="sort('amountQuarter')">
            <span style="text-wrap: nowrap;">
              Заказы за квартал <i class="fa" [ngClass]="{'fa-sort': sortField !== 'amountQuarter', 'fa-sort-up': sortField === 'amountQuarter' && sortOrder === 'asc', 'fa-sort-down': sortField === 'amountQuarter' && sortOrder === 'desc'}"></i>
            </span>
          </th>
          
          <th (click)="sort('vikupMonth')">
            <span style="text-wrap: nowrap;">
              Выкуп за месяц <i class="fa" [ngClass]="{'fa-sort': sortField !== 'vikupMonth', 'fa-sort-up': sortField === 'vikupMonth' && sortOrder === 'asc', 'fa-sort-down': sortField === 'vikupMonth' && sortOrder === 'desc'}"></i>
            </span>
          </th>
          <th (click)="sort('vikupQuarter')">
            <span style="text-wrap: nowrap;">
              Выкуп за квартал <i class="fa" [ngClass]="{'fa-sort': sortField !== 'vikupQuarter', 'fa-sort-up': sortField === 'vikupQuarter' && sortOrder === 'asc', 'fa-sort-down': sortField === 'vikupQuarter' && sortOrder === 'desc'}"></i>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        @for (user of usersSorted; track $index) {
        <tr [ngClass]="{'new': user.status === 'new','retail': user.status === 'retail', 'loyal': user.status ==='loyal', 'risk': user.status ==='risk', 'loss': user.status ==='loss'}">
          <td>{{ $index+1 }}</td>
          <td>{{ user.bitrixID }}</td>
          <td>{{ user.phone }}</td>
          <td>{{ user.name }}</td>
          <td>{{ user.amountMonth }}</td>
          <td>{{ user.amountQuarter }}</td>
          <td>{{ user.vikupMonth | number}}</td>
          <td>{{ user.vikupQuarter | number}}</td>
        </tr>
        }
      </tbody>
    </table>
  </div>
  } 
</div>
